<!--
 * @Author: Leif
 * @Date: 2021-11-05 13:44:27
 * @LastEditTime: 2021-11-28 18:33:44
 * @LastEditors: Please set LastEditors
 * @Description: 视频调度 - 视频调度首页
-->
<template>
  <div class="wrap">
    <HomeHead :isHome="true" />
    <div class="container">
      <div class="main">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import HomeHead from "components/HomeHead";

export default {
  name: "Home",
  data() {
    return {
      EventLogTran: false,
    };
  },
  components: {
    HomeHead,
  },
  props: {},
  methods: {
    goBack() {},
  },
  created() {},
  beforeUnmount() {},
};
</script>

<style lang="scss"></style>
